import React from "react"
import Header from '../components/Header';
import { graphql, Link } from "gatsby";

const Layout = ({data}) => {
  const { edges } = data.allMarkdownRemark;
  return (
    <div style={{
      // backgroundColor: '#303841',
    }}>
      <Header />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Segoe UI',
      }}>
        {edges.map(edge => {
          const { frontmatter } = edge.node;
          return (
            <div
              key={frontmatter.title}
              style={{
                marginBottom: '1rem',
              }}
            >
              <Link
                to={frontmatter.path}
              >
                {frontmatter.title}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            date
          }
        }
      }
    }
  }
`

export default Layout
